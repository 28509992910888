import Toastify from "npm:toastify-js";

export const SuccessToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#43a158",
        },
        onClick: function () {},
    }).showToast();
};

export const AlertToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#17a2b8",
        },
        onClick: function () {},
    }).showToast();
};

export const ErrorToast = (text: string) => {
    Toastify({
        text: text,
        offset: { y: 40 },
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        stopOnFocus: true,
        style: {
            background: "#dc3545",
        },
        onClick: function () {},
    }).showToast();
};

